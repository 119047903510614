<template>
  <delete-confirm-modal-content :modal-name="modalName" :loading="loading" @confirm="deleteUserRole">
    <h5>
      Are you sure you want to remove access for <span class="highlight">{{ email }}</span
      >?
    </h5>
  </delete-confirm-modal-content>
</template>

<script>
import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

export default {
  props: {
    email: String,
    modalName: String,
    deleteUserRole: Function,
  },
  computed: {
    loading() {
      return this.$store.getters.familyWebsiteDeleteMemberLoadingState[this.email];
    },
  },
  components: {DeleteConfirmModalContent},
  name: 'FamilyWebsiteRoleDeleteConfirm',
};
</script>

<style scoped lang="scss">
.delete-confirm-modal-content {
  h5 {
    word-break: break-word;
  }
}
</style>
