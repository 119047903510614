<template>
  <div class="family-website-manage-page">
    <div class="readable_content">
      <div class="heading-4">Manage Research Reports</div>
      <div class="text-lg subheading">Manage who has access to your reports</div>
      <mcr-loading-indicator :loading="allFamilyWebsitesListSettingsLoadingState"></mcr-loading-indicator>
      <div v-for="website in allFamilyWebsitesListSettingsState" :key="website.username">
        <stand-out-block class="setting-block sites-links-block">
          <div v-if="showSiteUrl" class="site-link">Website username: {{ website.username }}</div>
          <div v-for="site of website.sites" class="site-link">
            <router-link :to="getFwsRoute(website, site)" target="_blank" class="text-md">
              <span>{{ site.display_name }}</span>
              <open-in-new-icon :size="20"></open-in-new-icon>
            </router-link>
          </div>
        </stand-out-block>
        <stand-out-block class="setting-block">
          <div class="heading-6">Invite people to view your reports</div>
          <div v-if="showSiteUrl" class="subheading">Website username: {{ website.username }}</div>
          <div class="separator" />
          <family-website-manage-invite-input
            :username="website.username"
            @invited="onMembersInvited"
          ></family-website-manage-invite-input>
          <div class="privacy-setting">
            <div class="select-container">
              <multiselect
                class="bordered medium-size"
                :value="getPrivacyValueOption(website)"
                placeholder=""
                :searchable="false"
                :internal-search="false"
                :preserveSearch="false"
                :clearOnSelect="false"
                :show-no-options="true"
                :hide-selected="false"
                :options="privacyOptions"
                track-by="value"
                label="label"
                :showLabels="false"
                @select="onPrivacyChange(website.username, $event)"
              >
              </multiselect>
              <div class="text-xs">{{ getPrivacyLabel(website) }}</div>
            </div>
            <bounce-loading
              v-if="familyWebsiteSettingsLoadingState[website.username]"
              class="share-link-loading dark"
            ></bounce-loading>
            <transition name="fade" v-else>
              <share-link v-if="getPrivacyValue(website)" :href="getFwsHref(website)"></share-link>
            </transition>
          </div>
          <div class="separator" v-if="website.site_members.length" />
          <div class="heading-6" v-if="website.site_members.length">Access List</div>
          <div class="site-member" v-for="member in website.site_members" :key="member.email">
            <div>{{ member.email }}</div>
            <div><a @click="openConfirmRemove(website, member)">Remove</a></div>
          </div>
        </stand-out-block>
      </div>
    </div>
  </div>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import ShareLink from '@common/elements/links/ShareLink';
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

import FamilyWebsiteManageInviteInput from './FamilyWebsiteManageInviteInput';
import FamilyWebsiteRoleDeleteConfirm from './FamilyWebsiteRoleDeleteConfirm';

export default {
  created() {
    this.$store.dispatch('fetchAllMyFamilyWebsitesSettingsAction');
  },
  computed: {
    ...mapGetters([
      'allFamilyWebsitesListSettingsState',
      'allFamilyWebsitesListSettingsLoadingState',
      'familyWebsiteSettingsLoadingState',
    ]),
    showSiteUrl() {
      return this.allFamilyWebsitesListSettingsState.length > 1;
    },
    privacyOptions() {
      return [
        {
          value: false,
          label: 'Private',
          help_text: 'Only you and the people you invite will be able to see the reports.',
        },
        {value: true, label: 'Public', help_text: 'Anyone with the share link can view the reports.'},
      ];
    },
    confirmDeleteUserRoleModalName() {
      return 'remove-member-access';
    },
  },
  methods: {
    getFwsRoute(website, site) {
      return {name: 'jlt-report-site-name', params: {username: website.username, sitename: site.name}};
    },
    getFwsHref(website) {
      const data = this.$router.resolve(this.getFwsRoute(website, website.sites[0]));
      return `${window.location.origin}${data.href}`;
    },
    getPrivacyValue(website) {
      return website && website.sites ? website.sites.some(site => site.is_public) : false;
    },
    getPrivacyValueOption(website) {
      const value = this.getPrivacyValue(website);
      return this.privacyOptions.find(item => item.value === value);
    },
    onPrivacyChange(username, privacyValue) {
      this.$store
        .dispatch('updateMyFamilyWebsiteSettingsAction', {username, is_public: privacyValue.value})
        .then(() => {
          this.$toasted.success('Privacy settings updated');
        })
        .catch(() => {
          this.$toasted.error('Something went wrong. Please try again later.');
        });
    },
    getPrivacyLabel(website) {
      const value = this.getPrivacyValueOption(website);
      return value ? value.help_text : '';
    },
    onMembersInvited({username, list}) {
      console.log('onMembersInvited', username, list);
      this.$store.commit('mutateAllFamilyWebsitesListSettingsState', {username, site_members: list});
    },
    deleteUserRole(username, email) {
      return this.$store
        .dispatch('familyWebsiteDeleteMemberAction', {username, email})
        .then(response => {
          this.$store.commit('mutateAllFamilyWebsitesListSettingsState', {
            username,
            site_members: response.site_members,
          });
          this.$toasted.success(`Access removed for ${email}!`);
          this.$modal.hide(this.confirmDeleteUserRoleModalName);
        })
        .catch(() => {
          this.$toasted.error('Error removing user role. Changes were not saved.');
        });
    },
    openConfirmRemove(website, member) {
      let modalParams = {
        classes: 'clear_modal white_modal',
        class: 'mobile_bottom',
        name: this.confirmDeleteUserRoleModalName,
      };
      const props = {
        email: member.email,
        modalName: this.confirmDeleteUserRoleModalName,
        deleteUserRole: () => {
          return this.deleteUserRole(website.username, member.email);
        },
      };
      this.$modal.show(FamilyWebsiteRoleDeleteConfirm, props, modalParams);
    },
  },
  components: {FamilyWebsiteManageInviteInput, BounceLoading, Multiselect, StandOutBlock, OpenInNewIcon, ShareLink},
  name: 'FamilyWebsiteManagePage',
};
</script>

<style scoped lang="scss">
.family-website-manage-page {
  .subheading {
    margin-top: 4px;
    color: $neutral-600;
  }
  .heading-6 {
    margin: 0;
  }
  .setting-block {
    margin-top: 24px;
    .separator {
      padding-top: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid $neutral-200;
    }
  }
  .sites-links-block {
    padding-top: 0;
    padding-bottom: 0;
    .site-link {
      padding: 16px 0;
      font-weight: 500;
      .material-design-icon {
        vertical-align: middle;
        margin-left: 12px;
      }
    }
    .site-link:not(:last-of-type) {
      border-bottom: 1px solid $neutral-200;
    }
  }
  .site-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    a {
      font-weight: 500;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .site-member:not(:last-of-type) {
    border-bottom: 1px solid $neutral-200;
  }
  .site-member:last-of-type {
    padding-bottom: 0;
  }

  .privacy-setting {
    margin-top: 16px;
  }

  .select-container {
    display: flex;
    align-items: center;

    .multiselect {
      width: 90px;
      flex-shrink: 0;
      margin-right: 8px;
      .multiselect__tags {
        cursor: pointer;
      }
    }

    .text-xs {
      color: $neutral-500;
    }
  }

  .share-link-loading,
  .share-link {
    margin-top: 10px;
  }
}
</style>
