<template>
  <div class="family-website-manage-invite-input">
    <div class="input-container">
      <input
        placeholder="Enter emails separated by a comma"
        class="input"
        :class="{error: emailsErrors.length > 0}"
        v-model="inviteEmails"
        @keydown.enter="inviteClick"
      />
      <mcr-button :disabled="inviteDisabled" :loading="loading" @click="inviteClick">Invite</mcr-button>
    </div>
    <div class="input-error" v-for="error in emailsErrors">{{ error }}</div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {isEmailValid} from '@common/utils/utils';
import {mapGetters} from 'vuex';

export default {
  props: {
    username: [String, Number],
  },
  data() {
    return {
      inviteEmails: '',
      emailsErrors: [],
    };
  },
  computed: {
    ...mapGetters(['familyWebsiteAddMembersLoadingState']),
    inviteDisabled() {
      return this.inviteEmails.length === 0;
    },
    loading() {
      return this.familyWebsiteAddMembersLoadingState[this.username];
    },
  },
  methods: {
    inviteClick() {
      this.emailsErrors = [];
      const emails = this.inviteEmails
        .split(',')
        .map(email => email.trim())
        .filter(email => !!email);
      for (let email of emails) {
        if (!isEmailValid(email)) {
          this.emailsErrors.push(`"${email}" is not a valid email.`);
        }
      }
      if (this.emailsErrors.length > 0) {
        return;
      }
      this.$store
        .dispatch('familyWebsiteAddMembersAction', {
          username: this.username,
          emails: emails,
        })
        .then(response => {
          this.$emit('invited', {list: response.site_members, username: this.username});
          this.$toasted.success(`Users invited successfully!`);
          this.inviteEmails = '';
        })
        .catch(() => {
          this.$toasted.error('Error inviting users. Changes were not saved.');
        });
    },
  },
  components: {McrButton},
  name: 'FamilyWebsiteManageInviteInput',
};
</script>

<style lang="scss" scoped>
.family-website-manage-invite-input {
  .input-container {
    display: flex;
    input {
      width: 100%;
      min-width: 0;
      display: flex;
      flex-grow: 1;
    }
    .mcr-button {
      flex-shrink: 0;
      width: 78px;
      margin-left: 12px;
    }
  }
  .input-container + .input-error {
    margin-top: 8px;
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    .input-container {
      flex-direction: column;

      .mcr-button {
        width: 100%;
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
}
</style>
