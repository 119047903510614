<template>
  <div class="share-link">
    <copy-icon class="copy-icon" :size="22"></copy-icon>
    <input
      class="link input"
      ref="link"
      readonly
      :value="href"
      v-clipboard:copy="href"
      v-clipboard:success="copyLinkSuccess"
    />
    <transition name="fade">
      <div v-if="displayCopiedSuccess" class="copy-success">Copied!</div>
    </transition>
  </div>
</template>

<script>
import CopyIcon from 'vue-material-design-icons/ContentCopy';

export default {
  props: {
    href: String,
  },
  data() {
    return {
      displayCopiedSuccess: false,
    };
  },
  methods: {
    copyLinkSuccess() {
      this.$refs.link.select();
      this.displayCopiedSuccess = true;
      setTimeout(() => {
        this.displayCopiedSuccess = false;
      }, 1000);
    },
  },
  components: {
    CopyIcon,
  },
  name: 'ShareLink',
};
</script>

<style scoped lang="scss">
.share-link {
  position: relative;
  width: 100%;
  .copy-icon {
    position: absolute;
    right: 11px;
    top: 11px;
  }
  .copy-success {
    position: absolute;
    border-radius: 4px;
    right: 7px;
    top: 6px;
    padding: 4px;
    background-color: $success-color;
    color: $off-white;
  }
  .link {
    width: 100%;
    cursor: pointer;
  }
}
</style>
