<template>
  <mcr-jlt-report v-if="isReportFirstVersion"></mcr-jlt-report>
  <family-website-page v-else-if="isReportSecondVersion"></family-website-page>
  <div v-else-if="showAccessDenied" class="readable_content">
    <h1>This Research Report is private!</h1>
    <div class="heading-6">You can try...</div>
    <ul>
      <li v-if="myFwsRoute"><router-link :to="myFwsRoute">Go to your research report</router-link></li>
      <li><router-link :to="toLogin">Logging in</router-link> to an account that has access to the report</li>
      <li>Check if the URL is correct</li>
      <li>Ask owner of the report to make it public</li>
    </ul>
  </div>
  <mcr-loading-indicator v-else :loading="true" message="Loading Report..."></mcr-loading-indicator>
</template>

<script>
import {mapGetters} from 'vuex';

import FamilyWebsitePage from '@/components/modules/familyWebsite/FamilyWebsitePage';
import McrJltReport from '@/components/modules/jlt_report/jlt.report';

export default {
  created() {
    this.showAccessDenied = false;
    this.$store.dispatch('fetchFamilyWebsiteListAction', this.username);
    let params = {
      username: this.username,
      siteName: this.siteName,
      partKey: this.partKey,
      keepImageAssetId: this.keepImageAssetId,
    };
    this.$store.dispatch('fetchFamilyWebsiteDetailsAction', params).catch(err => {
      if (err && err.response && err.response.status === 403) {
        this.showAccessDenied = true;
        return;
      }
      this.$toasted.error('Something went wrong. Please try again later!');
      console.error(new Error(`FWS Report loading error: err=${err}; err.response=${err.response}`));
    });
  },
  data() {
    return {
      showAccessDenied: false,
    };
  },
  computed: {
    ...mapGetters(['familyWebsiteDetailsState', 'familyWebsiteListState', 'userFwsUsernameState']),
    username() {
      return this.$route.params.username;
    },
    siteName() {
      return this.$route.params.sitename;
    },
    partKey() {
      return this.$route.params.partkey || 'default';
    },
    isReportFirstVersion() {
      return (
        this.familyWebsiteListState && this.familyWebsiteDetailsState && this.familyWebsiteDetailsState.version == 1
      );
    },
    isReportSecondVersion() {
      return this.familyWebsiteDetailsState && this.familyWebsiteDetailsState.version == 2;
    },
    keepImageAssetId() {
      return this.isFWSReport ? 'true' : '';
    },
    toLogin() {
      return {name: 'login', query: {redirect: this.$route.fullPath}};
    },
    myFwsRoute() {
      if (this.userFwsUsernameState) {
        return {name: 'jlt-report-index', params: {username: this.userFwsUsernameState}};
      }
    },
  },
  components: {McrJltReport, FamilyWebsitePage},
};
</script>

<style lang="scss" scoped></style>
